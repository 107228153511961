// ** React Imports

import {Fragment, lazy} from 'react'

// ** Layouts
import BlankLayout from '@core/layouts/BlankLayout'
import VerticalLayout from 'layouts/VerticalLayout'
import LayoutWrapper from '@core/layouts/components/layout-wrapper'

// ** Route Components
import PublicRoute from '@core/components/routes/PublicRoute'
import PrivateRoute from '@core/components/routes/PrivateRoute'

// ** Utils
import {isObjEmpty} from 'utility/Utils'

//****Import Admin
//Dashboard
const AdminDashboard = lazy(() => import('@services/admin/dashboard/pages'))

//Role-permission
const Role = lazy(() => import('@services/admin/role-permission/pages/roles'))
const RoleDetail = lazy(() =>
  import('@services/admin/role-permission/pages/roles/role-create'),
)
const UserRole = lazy(() =>
  import('@services/admin/role-permission/pages/user-roles'),
)

//Groups
const RoleGroup = lazy(() =>
  import('@services/admin/role-permission/pages/group-roles'),
)
const RoleGroupDetail = lazy(() =>
  import('@services/admin/role-permission/pages/group-roles/role-create'),
)

//Data-center
const BizxuAdmin = lazy(() => import('@services/admin/data-center/bizxu/pages'))
const LandingAdmin = lazy(() =>
  import('@services/admin/data-center/landing-page/pages'),
)
const DataCenterAdmin = lazy(() =>
  import('@services/admin/data-center/main/pages'),
)
const DetailsDataCenterAdmin = lazy(() =>
  import('@services/admin/data-center/main/pages/manage-data/DetailsData'),
)
const RequestTransactionAdmin = lazy(() =>
  import(
    '@services/admin/data-center/main/pages/request-details/RequestTransaction'
  ),
)
const RequestRefundAdmin = lazy(() =>
  import(
    '@services/admin/data-center/main/pages/request-details/RequestRefund'
    ),
)
const ConfigDataAdmin = lazy(() =>
  import('@services/admin/data-center/config-data/pages'),
)
const DetailsConfigDataAdmin = lazy(() =>
  import(
    '@services/admin/data-center/config-data/pages/manage-data/DetailsData'
  ),
)
const ProjectAdmin = lazy(() =>
  import('@services/admin/data-center/project/pages'),
)
const ProjectDetails = lazy(() =>
  import('@services/admin/data-center/project/pages/project-details'),
)
const ProjectCreate = lazy(() =>
  import('@services/admin/data-center/project/pages/create-project'),
)
const LandingpageCreate = lazy(() =>
  import('@services/admin/data-center/landing-page/pages/CreateLandingpage'),
)
// const LandingpageCheckout = lazy(() =>
//   import(
//     '@services/data-center/landing-page/pages/checkout/CheckoutBuyLandingpage'
//   ),
// )
const LandingpageDetail = lazy(() =>
  import('@services/data-center/landing-page/pages/items/LandingpageDetail'),
)
const PurchaseRequestDetails = lazy(() =>
  import('@services/admin/data-center/bizxu/pages/purchase-equest-details'),
)
const SourceManagement = lazy(() =>
  import('@services/admin/data-center/source-management'),
)
const CreateSource = lazy(() =>
  import('@services/admin/data-center/source-management/create-source'),
)
const UpdateSource = lazy(() =>
  import('@services/admin/data-center/source-management/update-source'),
)

const DetailSource = lazy(() =>
  import('@services/admin/data-center/source-management/detail-source'),
)

const DataManually = lazy(() =>
  import('@services/admin/data-center/data-manually'),
)

//Customer
const CustomerLeads = lazy(() =>
  import('@services/admin/customer/customer-leads/pages'),
)
const ExploitCustomerLeads = lazy(() =>
  import('@services/admin/customer/exploit-customer-leads/pages'),
)
const Customer = lazy(() => import('@services/admin/customer/main/pages'))

//Community
const CommunityCourse = lazy(() =>
  import('@services/admin/community/course/pages'),
)
const CommunityManage = lazy(() =>
  import('@services/admin/community/manage/pages'),
)
const CommunityMember = lazy(() =>
  import('@services/admin/community/member/pages'),
)
const CommunityRequestAccess = lazy(() =>
  import('@services/admin/community/request-access/pages'),
)
const CommunityEvent = lazy(() =>
  import('@services/admin/community/event/pages'),
)

const CommunityGroup = lazy(() =>
  import('@services/admin/community/group/pages'),
)

const CommunityPost = lazy(() => import('@services/admin/community/post/pages'))

//Other-function
const Notification = lazy(() =>
  import('@services/admin/other-function/notification/pages'),
)
const Data = lazy(() => import('@services/admin/other-function/data/pages'))
const Account = lazy(() =>
  import('@services/admin/other-function/account/pages'),
)
const Manage = lazy(() => import('@services/admin/other-function/manage/pages'))

//System
const ManageLabel = lazy(() =>
  import('@services/admin/system/manage-label/pages'),
)

const ActivityLevel = lazy(() =>
  import('@services/admin/system/activity-level/pages'),
)
const ManageGift = lazy(() =>
  import('@services/admin/system/manage-gift/pages'),
)

const ViolationPolicy = lazy(() =>
  import('@services/admin/system/violation-policy/pages'),
)

// Profile
const Profile = lazy(() => import('@services/home/profile/pages'))

//****Import Auth
const Login = lazy(() => import('@services/auth/pages/login'))
const Register = lazy(() => import('@services/auth/pages/register'))
const Forgot = lazy(() => import('@services/auth/pages/forgot-password'))
const Reset = lazy(() => import('@services/auth/pages/reset-password'))
const Verify = lazy(() => import('@services/auth/pages/verify-account'))

//****Import Data-center
const Bizxu = lazy(() => import('@services/data-center/bizxu/pages'))
const PaymentReceiveData = lazy(() =>
  import(
    '@services/data-center/bizxu/pages/payment-receive-data/PaymentReceiveData'
  ),
)
const MemberBuyBizxu = lazy(() =>
  import('@services/data-center/bizxu/pages/member_buy_bizxu/Items'),
)
const MemberStoreBizxu = lazy(() =>
  import('@services/data-center/bizxu/pages/member_buy_bizxu/StoreBizxu'),
)
const DataProject = lazy(() =>
  import('@services/data-center/data-project/pages'),
)
const DetailsProject = lazy(() =>
  import(
    '@services/data-center/data-project/pages/discover-project/DetailsProject'
  ),
)
const DetailsData = lazy(() =>
  import(
    '@services/data-center/data-project/pages/discover-project/DetailsData'
  ),
)
const BuyProject = lazy(() =>
  import(
    '@services/data-center/data-project/pages/discover-project/BuyProject'
  ),
)
// const DetailsData = lazy(() => import('@services/data-center/data-project/pages/discover-project/DetailsData'))
const CustomerEx = lazy(() => import('@services/data-center/customer-ex/pages'))
const LandingPage = lazy(() =>
  import('@services/data-center/landing-page/pages'),
)
const LandingPageRegister = lazy(() =>
  import('@services/data-center/landing-page/pages/checkout'),
)
const MyData = lazy(() => import('@services/data-center/my-data/pages'))

const RequestRefundMember = lazy(() =>
  import(
    '@services/data-center/my-data/pages/request-details/RequestRefund'
    ),
)
//****Import Home
//import Event
const Event = lazy(() => import('@services/home/event/pages'))
const EventDetail = lazy(() => import('@services/home/event/pages/detail'))
const CreateEvaluate = lazy(() =>
  import('@services/home/event/pages/evaluate/CreateEvaluate'),
)
const EventEvaluate = lazy(() =>
  import('@services/home/event/pages/evaluate/Evaluate'),
)
// const resourceEvent = 'SOCIAL_NETWORK_EVENT'

//import Group
const Group = lazy(() => import('@services/home/group/pages'))
const GroupDetail = lazy(() =>
  import('@services/home/group/pages/GroupDetailPages'),
)
const GroupCreate = lazy(() =>
  import('@services/home/group/pages/create-group'),
)
// const resourceGroup = 'SOCIAL_NETWORK_GROUP'

//import Notification
const NotificationHome = lazy(() => import('@services/home/notification/pages'))

//import RequestAccess
const RequestAccess = lazy(() => import('@services/home/request-access/pages'))

const Home = lazy(() => import('@services/home/news-feed/pages'))
const PostDetail = lazy(() =>
  import('@services/home/news-feed/pages/post-detail'),
)

const resource = 'SOCIAL_NETWORK_NEWSFEED'

//****Import Profile
// const Profile = lazy(() => import('@services/home/profile/pages'))

//****Import Test
const Modal = lazy(() => import('@services/test/pages/modal'))
const DataTable = lazy(() => import('@services/test/pages/data-table'))
const FormField = lazy(() => import('@services/test/pages/form-field'))
const FormValidate = lazy(() => import('@services/test/pages/form-validate'))
const Table = lazy(() => import('@services/test/pages/tab'))

//****Import Training
const StartUp = lazy(() => import('@services/training/startup/pages'))
const Skill = lazy(() => import('@services/training/skill/pages'))

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  // horizontal: <HorizontalLayout />,
}

// ** Document title
const TemplateTitle = '%s - Mạng cộng đồng hSpace'

// ** Default Route
const DefaultRoute = '/home'

let AdminRoutes = [
  //Dashboard
  {
    path: '/admin/dashboard',
    element: <AdminDashboard />,
    meta: {resource: 'ADMIN'},
  },
  // Data-center
  {
    path: '/admin/data-center/bizxu',
    element: <BizxuAdmin />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  {
    path: '/admin/data-center/bizxu/:type',
    element: <BizxuAdmin />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  {
    path: '/admin/data-center/landing-page',
    element: <LandingAdmin />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_LANDING_PAGE'},
  },
  {
    path: '/admin/data-center/main',
    element: <DataCenterAdmin />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_CUSTOMER_DATA'},
  },
  {
    path: '/admin/data-center/main/request-transfer/:id',
    element: <RequestTransactionAdmin />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_CUSTOMER_DATA'},
  },
  {
    path: '/admin/data-center/main/request-refund/:id',
    element: <RequestRefundAdmin />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_CUSTOMER_DATA'},
  },
  {
    path: '/admin/data-center/main/:id',
    element: <DetailsDataCenterAdmin />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_CUSTOMER_DATA'},
  },
  {
    path: '/admin/data-center/config-data',
    element: <ConfigDataAdmin />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  {
    path: '/admin/data-center/config-data/:id',
    element: <DetailsConfigDataAdmin />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  {
    path: '/admin/data-center/project',
    element: <ProjectAdmin />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  {
    path: '/admin/data-center/project/project-details/:id',
    element: <ProjectDetails />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  {
    path: '/admin/data-center/project/add-project',
    element: <ProjectCreate />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  {
    path: '/admin/data-center/project/update-project/:id',
    element: <ProjectCreate />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  {
    path: '/admin/data-center/landing-page/add-landingpage',
    element: <LandingpageCreate />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_LANDING_PAGE'},
  },
  {
    path: '/admin/data-center/landing-page/update-landingpage/:id',
    element: <LandingpageCreate />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_LANDING_PAGE'},
  },
  {
    path: '/admin/data-center/source-management',
    element: <SourceManagement />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  {
    path: '/admin/data-center/source-management/add-source',
    element: <CreateSource />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  {
    path: `/admin/data-center/source-management/update-source/:id`,
    element: <UpdateSource />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  {
    path: '/admin/data-center/data-manually/:id',
    element: <DataManually />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  {
    path: `/admin/data-center/source-management/detail-source/:type/:id`,
    element: <DetailSource />,
    meta: {resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU'},
  },
  // Customer
  {
    path: '/admin/customer/customer-leads',
    element: <CustomerLeads />,
    meta: {resource: 'BIZXU'},
  },
  {
    path: '/admin/customer/exploit-customer-leads',
    element: <ExploitCustomerLeads />,
    meta: {resource: 'BIZXU'},
  },
  {
    path: '/admin/customer/main',
    element: <Customer />,
    meta: {resource: 'BIZXU'},
  },
  // community
  {
    path: '/admin/community/course',
    element: <CommunityCourse />,
    meta: {resource: 'BIZXU'},
  },
  {
    path: '/admin/community/manage',
    element: <CommunityManage />,
    meta: {resource: 'BIZXU'},
  },
  {
    path: '/admin/community/member',
    element: <CommunityMember />,
    meta: {resource: 'ADMIN_SOCIAL_NETWORK_COMMUNITY'},
  },
  {
    path: '/admin/community/member/:type',
    element: <CommunityMember />,
    meta: {resource: 'ADMIN_SOCIAL_NETWORK_COMMUNITY'},
  },
  {
    path: '/admin/community/request-access',
    element: <CommunityRequestAccess />,
    meta: {
      resource: 'ADMIN_SOCIAL_NETWORK_ACCESS_REQUEST',
    },
  },
  {
    element: <CommunityPost />,
    path: '/admin/community/post',
    meta: {
      resource: 'ADMIN_SOCIAL_NETWORK_COMMUNITY',
    },
  },
  {
    element: <CommunityPost />,
    path: '/admin/community/post/:type',
    meta: {
      resource: 'ADMIN_SOCIAL_NETWORK_COMMUNITY',
    },
  },
  {
    element: <CommunityGroup />,
    path: '/admin/community/group',
    meta: {
      resource: 'ADMIN_SOCIAL_NETWORK_COMMUNITY',
    },
  },
  {
    element: <CommunityGroup />,
    path: '/admin/community/group/:type',

    meta: {
      resource: 'ADMIN_SOCIAL_NETWORK_COMMUNITY',
    },
  },
  {
    element: <CommunityEvent />,
    path: '/admin/community/event',
    meta: {
      resource: 'ADMIN_SOCIAL_NETWORK_COMMUNITY',
    },
  },
  {
    element: <CommunityEvent />,
    path: '/admin/community/event/:type',

    meta: {
      resource: 'ADMIN_SOCIAL_NETWORK_COMMUNITY',
    },
  },

  // Other-function
  {
    path: '/admin/setting/notification',
    element: <Notification />,
    meta: {resource: 'BIZXU'},
  },
  {
    path: '/admin/setting/config-data',
    element: <Data />,
    meta: {resource: 'BIZXU'},
  },
  {
    path: '/admin/setting/account',
    element: <Account />,
    meta: {resource: 'BIZXU'},
  },
  {
    path: '/admin/setting/manage',
    element: <Manage />,
    meta: {resource: 'BIZXU'},
  },
  // Role-permission
  {
    path: '/admin/role-permission/roles',
    element: <Role />,
    meta: {resource: 'ADMIN_ROLE_PERMISSTION_ROLE'},
  },
  {
    path: '/admin/role-permission/roles/:id',
    element: <RoleDetail />,
    meta: {resource: 'ADMIN_ROLE_PERMISSTION_ROLE'},
  },
  {
    path: '/admin/role-permission/users',
    element: <UserRole />,
    meta: {resource: 'ADMIN_ROLE_PERMISSTION_USER_ROLE'},
  },
  // **  GROUP ROLES
  {
    path: '/admin/role-permission/groups',
    element: <RoleGroup />,
    meta: {resource: 'ADMIN_ROLE_PERMISSTION_ROLE'},
  },
  {
    path: '/admin/role-permission/groups/:id',
    element: <RoleGroupDetail />,
    meta: {resource: 'ADMIN_ROLE_PERMISSTION_ROLE'},
  },
  // {
  //   element: <PermissionPage />,
  //   path: '/admin/role-permission/permissions',
  //   meta: {
  //     resource,
  //   },
  // },

  //  System

  {
    path: '/admin/system/label',
    element: <ManageLabel />,
    meta: {resource: 'ADMIN_DATA_CENTER_BIZXU'},
  },
  {
    path: '/admin/system/activity_level',
    element: <ActivityLevel />,
    meta: {resource: 'ADMIN_DATA_CENTER_BIZXU'},
  },
  {
    path: '/admin/system/gift',
    element: <ManageGift />,
    meta: {resource: 'ADMIN_DATA_CENTER_BIZXU'},
  },
  {
    path: '/admin/system/violate',
    element: <ViolationPolicy />,
    meta: {resource: 'ADMIN_DATA_CENTER_BIZXU'},
  },
  {
    path: '/admin/data-center/bizxu/purchase_equest_details/:id',
    element: <PurchaseRequestDetails />,
    meta: {resource: 'SOCIAL_NETWORK_EVENT'},
  },
]

let BlankRoutes = [
  {
    path: '/login',
    element: <Login />,
    meta: {layout: 'blank', publicRoute: true, restricted: true},
  },
  {
    path: '/register',
    element: <Register />,
    meta: {layout: 'blank', publicRoute: true, restricted: true},
  },
  {
    path: '/forgot-password',
    element: <Forgot />,
    meta: {layout: 'blank', publicRoute: true, restricted: true},
  },
  {
    path: '/reset-password',
    element: <Reset />,
    meta: {layout: 'blank', publicRoute: true, restricted: true},
  },
  {
    path: '/verify-account',
    element: <Verify />,
    meta: {layout: 'blank', publicRoute: true, restricted: true},
  },
]

let DataCenterRoutes = [
  {
    path: '/data-center/bizxu',
    element: <Bizxu />,
    meta: {resource: 'DATA_CENTER_BIZXU'},
  },
  {
    path: '/data-center/bizxu/payment-receive-data/:id',
    element: <PaymentReceiveData />,
    meta: {resource: 'DATA_CENTER_BIZXU'},
  },
  {
    path: '/data-center/bizxu/:type',
    element: <Bizxu />,
    meta: {resource: 'DATA_CENTER_BIZXU'},
  },
  {
    path: '/data-center/bizxu/member',
    element: <MemberBuyBizxu />,
    meta: {resource: 'DATA_CENTER_BIZXU'},
  },
  {
    path: '/data-center/bizxu/member/:id',
    element: <MemberStoreBizxu />,
    meta: {resource: 'DATA_CENTER_BIZXU'},
  },
  {
    path: '/data-center/buy-data',
    element: <DataProject />,
    meta: {resource: 'DATA_CENTER_BIZXU'},
  },
  {
    path: '/data-center/buy-data/:type',
    element: <DataProject />,
    meta: {resource: 'DATA_CENTER_BIZXU'},
  },
  {
    path: '/data-center/buy-data/buy-project/:id',
    element: <BuyProject />,
    meta: {resource: 'DATA_CENTER_BIZXU'},
  },
  // {
  //   path: '/data-center/buy-data/buy-project/:id?:type',
  //   element: <DetailsData/>,
  //   meta: { resource: 'DATA_CENTER_BIZXU' },
  // },
  {
    path: '/data-center/customer-experience',
    element: <CustomerEx />,
    meta: {resource: 'DATA_CENTER_BIZXU'},
  },
  {
    path: '/data-center/landing-page/',
    element: <LandingPage />,
    meta: {resource: 'DATA_CENTER_LANDING_PAGE'},
  },
  {
    path: '/data-center/landing-page?:type',
    element: <LandingPage />,
    meta: {resource: 'DATA_CENTER_LANDING_PAGE'},
  },
  {
    path: '/data-center/landing-page/:id',
    element: <LandingPageRegister />,
    meta: {resource: 'DATA_CENTER_LANDING_PAGE'},
  },
  // {
  //   path: '/data-center/landing-page/checkout/:id',
  //   element: <LandingpageCheckout />,
  //   meta: {resource: 'DATA_CENTER_LANDING_PAGE'},
  // },
  {
    path: '/data-center/landing-page/landing-page-detail/:id',
    element: <LandingpageDetail />,
    meta: {resource: 'DATA_CENTER_LANDING_PAGE'},
  },
  {
    path: '/data-center/landing-page/landing-page-detail/:id/:customer_id',
    element: <DetailsDataCenterAdmin />,
    meta: {resource: 'DATA_CENTER_LANDING_PAGE'},
  },
  {
    path: '/data-center/my-data',
    element: <MyData />,
    meta: {resource: 'DATA_CENTER_CUSTOMER_DATA'},
  },
  {
    path: '/data-center/my-data/:id',
    element: <DetailsDataCenterAdmin />,
    meta: {resource: 'DATA_CENTER_CUSTOMER_DATA'},
  },
  {
    path: '/data-center/my-data?:type',
    element: <MyData />,
    meta: {resource: 'DATA_CENTER_CUSTOMER_DATA'},
  },
  {
    path: '/data-center/my-data/request-refund/:id',
    element: <RequestRefundMember />,
    meta: {resource: 'DATA_CENTER_CUSTOMER_DATA'},
  },
  {
    path: '/admin/data-center/source-management',
    element: <SourceManagement />,
    meta: {resource: 'DATA_CENTER_BIZXU'},
  },
]

let HomeRoutes = [
  {path: '/home', element: <Home />, meta: {resource, publicRoute: false}},
  {path: '/home/:type', element: <Home />, meta: {resource}},
  {path: '/home/newsfeed/:postid', element: <PostDetail />, meta: {resource}},
  //Group
  {
    path: '/groups',
    element: <Group />,
    meta: {resource: 'SOCIAL_NETWORK_GROUP'},
  },
  {
    path: '/groups/:type',
    element: <Group />,
    meta: {resource: 'SOCIAL_NETWORK_GROUP'},
  },
  {
    path: '/groups/create-group',
    element: <GroupCreate />,
    meta: {resource: 'SOCIAL_NETWORK_GROUP'},
  },
  {
    path: '/groups/update-group/:id',
    element: <GroupCreate />,
    meta: {resource: 'SOCIAL_NETWORK_GROUP'},
  },
  {
    path: '/groups/detail/:id',
    element: <GroupDetail />,
    meta: {resource: 'SOCIAL_NETWORK_GROUP'},
  },
  {
    path: '/groups/detail/:id/:tab',
    element: <GroupDetail />,
    meta: {resource: 'SOCIAL_NETWORK_GROUP'},
  },
  //RequestAccess
  {
    path: '/request-access',
    element: <RequestAccess />,
    meta: {resource: 'SOCIAL_NETWORK_ACCESS_REQUEST'},
  },
  {
    path: '/request-access/:type',
    element: <RequestAccess />,
    meta: {resource: 'SOCIAL_NETWORK_ACCESS_REQUEST'},
  },
  //Notification
  {
    path: '/notification',
    element: <NotificationHome />,
    meta: {resource: 'SOCIAL_NETWORK_NOTI'},
  },
  //Event
  {
    path: '/events',
    element: <Event />,
    meta: {resource: 'SOCIAL_NETWORK_EVENT'},
  },
  {
    path: '/events/:type',
    element: <Event />,
    meta: {resource: 'SOCIAL_NETWORK_EVENT'},
  },
  {
    path: '/events/detail/:id',
    element: <EventDetail />,
    meta: {resource: 'SOCIAL_NETWORK_EVENT'},
  },
  {
    path: '/events/:type/:id',
    element: <Event />,
    meta: {resource: 'SOCIAL_NETWORK_EVENT'},
  },
  {
    path: '/events/create_evaluate/:target_id',
    element: <CreateEvaluate />,
    meta: {resource: 'SOCIAL_NETWORK_EVENT'},
  },
  {
    path: '/events/evaluate/:id',
    element: <EventEvaluate />,
    meta: {resource: 'SOCIAL_NETWORK_EVENT'},
  },
]

let ProfileRoutes = [
  {
    element: <Profile />,
    path: '/profile/:id/:type',
    meta: {
      // resource: 'PROFILE_PAGE_PERSONAL_NEWSFEED_PROFILE_SHOW_INFO_GET',
      publicRoute: true,
    },
  },
]

let TestRoutes = [
  {path: '/test/modal', element: <Modal />, meta: {resource}},
  {path: '/test/data-table', element: <DataTable />, meta: {resource}},
  {path: '/test/form-field', element: <FormField />, meta: {resource}},
  {path: '/test/form-validate', element: <FormValidate />, meta: {resource}},
  {path: '/test/tab', element: <Table />, meta: {resource}},
  {path: '/test/tab/:type', element: <Table />, meta: {resource}},
]

let TrainingRoutes = [
  // ** startup
  {
    path: '/training/startup',
    element: <StartUp />,
    meta: {resource: 'STARTUP_TRAINING'},
  },
  // **  skill
  {
    path: '/training/skill',
    element: <Skill />,
    meta: {resource: 'STARTUP_TRAINING'},
  },
]

let UserRoutes = [
  //Home
  ...HomeRoutes,
  //Profile
  ...ProfileRoutes,
  //Data-center
  ...DataCenterRoutes,
  // *** training
  ...TrainingRoutes,
  // *** Testing
  ...TestRoutes,

  // *** Profile
  ...ProfileRoutes,
]

// UserRoutes = UserRoutes.map(route => ({...route, meta: {resource: 'TEST'}}))

// AdminRoutes = AdminRoutes.map(route => ({...route, meta: {resource: 'TEST'}}))

// ** Merge Routes
const Routes = [...BlankRoutes, ...UserRoutes, ...AdminRoutes]

const getRouteMeta = route => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return {routeMeta: route.meta}
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter(route => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = layout => {
  const defaultLayout = layout || 'vertical'
  const layouts = ['vertical', 'horizontal', 'blank']

  const AllRoutes = []

  layouts.forEach(layoutItem => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: '/',
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
    })
  })
  return AllRoutes
}

export {DefaultRoute, TemplateTitle, Routes, getRoutes}
