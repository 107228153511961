const keys = {
  GET_LIST_USER_SYSTEM: 'GET_LIST_USER_SYSTEM',
  GET_PROFILE_USER: 'GET_PROFILE_USER',
  UPDATE_SOCIAL_NETWORK: 'UPDATE_SOCIAL_NETWORK',
  UPDATE_AVATAR_OR_COVER: 'UPDATE_AVATAR_OR_COVER',
  GET_DETAIL_PROFILE: 'GET_DETAIL_PROFILE',
  GET_DETAIL_PROFILE_VIEW: 'GET_DETAIL_PROFILE_VIEW',
  GET_GENERAL_PROFILE_DATA: 'GET_GENERAL_PROFILE_DATA',
  GET_SOCIAL_INFO: 'GET_SOCIAL_INFO',
  UPDATE_OVERVIEW_PROFILE: 'UPDATE_OVERVIEW_PROFILE',
  GET_CODE_CHANGE_PASSWORD: 'GET_CODE_CHANGE_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHECK_FOLLOW: 'CHECK_FOLLOW',
  GET_FOLLOWER: 'GET_FOLLOWER',
  GET_FOLLOWING: 'GET_FOLLOWING',
  GET_POST_BOOKMARK: 'GET_POST_BOOKMARK',
  GET_PROFILE_REFERENCE: 'GET_PROFILE_REFERENCE',
  PROFILESYSTEMSTATISTIC: 'PROFILESYSTEMSTATISTIC',
}

export default keys
